import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO title="Kevin Young: About" />
    <section>
      <div className="boxed-container">
        <div className="text-block">
          <p>Kevin Young is a Toronto-based writer, keyboard player and composer.</p>
          <p>As a writer with over 20 years of experience Kevin has covered a diverse variety of topics ranging from the use, installation and care and feeding of music and production technology, to where to find the sheep’s head stew in Fez.</p>
          <p>A founding member of 90s Canadian alt. Rock band Moist, he has toured widely, performing in a glittering panorama of all night bowling alleys, hockey arenas, former public toilets and reconverted porno theaters from the high Arctic to the Deep South.</p>
          <p>Kevin is also an amateur photographer with a peculiar but abiding interest in abstract images of Eastern and Central European, Soviet era, public infrastructure.</p>
          <p>He continues to travel regularly and now splits his time between touring, recording, composing, and writing for fun and profit, and is a regular contributor to a variety of Canadian and International production trade publications.</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default About